@mixin NFS-xs {
    @media (max-width: 575.98px) {
        @content;
    }
}

@mixin NFS-sm {
    @media (min-width: 575.98px) {
        @content;
    }
}

@mixin NFS-md {
    @media (min-width: 767.98px) {
        @content;
    }
}

@mixin NFS-lg {
    @media (min-width: 991.98px) {
        @content;
    }
}

@mixin NFS-xl {
    @media (min-width: 1199.98px) {
        @content;
    }
}

@mixin NFS-pre-xs {
    @media (max-width: 575.98px) {
        @content;
    }
}

@mixin NFS-pre-sm {
    @media (max-width: 575.98px) {
        @content;
    }
}

@mixin NFS-pre-md {
    @media (max-width: 767.98px) {
        @content;
    }
}

@mixin NFS-pre-lg {
    @media (max-width: 991.98px) {
        @content;
    }
}

@mixin NFS-pre-xl {
    @media (max-width: 1199.98px) {
        @content;
    }
}

@mixin NFS-size($property, $web-value, $mobile-value) {
    @include NFS-xs {
        #{$property}: $mobile-value;
    }

    @include NFS-sm {
        #{$property}: (2 * $mobile-value + $web-value)/3;
    }

    @include NFS-md {
        #{$property}: ($mobile-value + $web-value)/2;
    }

    @include NFS-lg {
        #{$property}: ($mobile-value + 2 * $web-value)/3;
    }

    @include NFS-xl {
        #{$property}: $web-value;
    }
}

@mixin rtl {
    @at-root body[dir='rtl'] & {
        @content;
    }
}

@mixin ltr {
    @at-root body[dir='ltr'] & {
        @content;
    }
}
