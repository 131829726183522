@import 'src/utilities/references/index';

.nfs-history-order-card {
    min-width: 320px;
    height: 196px;
    flex: 1;
    background-color: $gray1B;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    .spacer {
        flex: 1;
    }
    .header {
        display: flex;
        flex-direction: column;
        .content {
            padding: 16px;
            display: flex;
            align-items: center;
            padding-bottom: 16px;
            width: calc(100% - 32px);
            padding: 0 16px 16px;
            .name {
                display: flex;
                align-items: center;
                gap: 8px;
                .title {
                    display: flex;
                    flex-direction: column;
                    gap: 2px;
                    .item {
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
                .icon {
                    display: flex;
                    .item {
                        width: 31px;
                        height: 31px;
                        border-radius: 100%;
                        background: $gray1B;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        z-index: 1;
                        &:last-child {
                            margin-right: -12px;
                        }
                        > img {
                            width: 28px;
                            height: 28px;
                            border-radius: 100%;
                        }
                    }
                    &.en {
                        .item:last-child {
                            margin-left: -12px;
                        }
                    }
                    &.fa {
                        .item:last-child {
                            margin-right: -12px;
                        }
                    }
                }
            }
            .badge {
                font-size: 12px;
                font-weight: 500;
                min-width: 94px;
                white-space: nowrap;
                padding: 0 16px;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                &.waiting {
                    background-color: rgba(#0084ff, 0.1);
                    color: #0084ff;
                }
                &.doing {
                    background-color: rgba(#ffc500, 0.1);
                    color: #ffc500;
                }
                &.done {
                    background-color: rgba(#2bcc92, 0.1);
                    color: #2bcc92;
                }
            }
        }
        .line {
            width: 100%;
            height: 3px;
            background: radial-gradient(11px 20px, rgb(255 255 255 / 10%) 69%, transparent 87%) 0 0 / 26px 100%;
        }
    }
    .body-content {
        padding: 16px 16px 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .row {
            display: flex;
            justify-content: space-between;
            height: 100%;
            width: 100%;
            flex: 1;
            align-items: center;
            .item {
                display: flex;
                flex-direction: column;
                gap: 6px;
                > p {
                    font-size: 12px;
                    font-weight: 400;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    &:last-child {
                        direction: ltr !important;
                    }
                }
                &:last-child {
                    > p {
                        direction: ltr !important;
                    }
                }
            }
            &:first-child {
                .item {
                    max-width: 30%;
                }
            }
            &:last-child {
                .item {
                    p:last-child {
                        direction: rtl !important;
                    }
                }
            }
        }
    }
}
