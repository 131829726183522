@import 'src/utilities/references/index';

.nfs-checkbox {
    display: flex;
    gap: 8px;
    transition: opacity 0.2s ease-in-out;
    .checkmark {
        min-width: 24px;
        max-width: 24px;
        width: 100%;
        min-height: 24px;
        max-height: 24px;
        height: 100%;
        border-radius: 8px;
        border: solid 1px rgba(#ffffff, 0.1);
        cursor: pointer;
        transition: opacity 0.2s ease-in-out;
        background: $gray1B;
        overflow: hidden;
        display: flex;
        &:hover {
            opacity: 0.8;
        }
        .fill {
            background: $primary;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
            > * {
                width: 18px;
                height: 18px;
                color: #ffffff;
            }
            &.active {
                opacity: 1;
            }
        }
    }
    > label {
        font-size: 12px;
        font-weight: 300;
        color: #ffffff;
        line-height: 22px;
    }
    &.disabled {
        opacity: 0.5;
        &,
        .checkmark {
            cursor: not-allowed;
        }
    }
}
