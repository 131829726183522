@import './font';
@import './card';
@import './inputs';
@import '~react-toastify/dist/ReactToastify.css';
@import 'src/utilities/references/index';

html {
    scroll-behavior: smooth;
}

body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    background-color: $background;
    // overflow: hidden;

    &[dir='ltr'] {
        direction: ltr;
    }

    &[dir='rtl'] {
        direction: rtl;
    }

    #root {
        overflow: hidden;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

a {
    outline: none;
    text-decoration: none;
    transition: opacity 0.3s ease-in-out;
}

a:hover {
    text-decoration: none;
    opacity: 0.9 !important;
}
