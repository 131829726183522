@import 'src/utilities/references/index';

.nfs-modal {
    &-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 376px;
        width: 100%;
        background-color: $gray15;
        border-radius: 16px;
        border: solid 1px rgba(#ffffff, 0.05);
        outline: none;
        @include NFS-pre-md {
            max-width: calc(100% - 48px);
        }
        &-title {
            display: flex;
            align-items: center;
            height: 68px;
            border-bottom: solid 1px rgba(#ffffff, 0.05);
            padding: 0 24px;
            .spacer {
                flex: 1;
            }
            > h4 {
                font-size: 14px;
                font-weight: 500;
                color: #ffffff;
            }
            > .icon {
                min-width: 24px;
                min-height: 24px;
                max-width: 24px;
                max-height: 24px;
                width: 100%;
                height: 100%;
                color: rgba(#ffffff, 0.5);
                cursor: pointer;
                transition: opacity 0.2s ease-in-out;
                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
}
