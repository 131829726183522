@use 'sass:selector';

$primary: linear-gradient(90deg, rgba(229, 4, 45, 1) 0%, rgba(153, 0, 34, 1) 100%);
$gray70: #707070;
$gray1B: #1b1b1b;
$gray15: #151515;
$blue: #0084ff;
$background: #151515;
$gray36: #363636;
$gray99: #999999;
$blue3E: #3ebfea;
$blue1D: #1dd0d6;
$gray88: #888888;
$navy3F: #3f4d67;
$navy49: #495269;
$blue0D: #0db2b8;
$bgF2: #f2fcfc;
$red: #eb496a;
$green: #1dd6a4;
$greenSuccess: #00FF47;
$grayAE: #aeb2b7;
$grayB4: #b4b4b4;
$grayD9: #d9d9d9;
$gray89: #8999ae;
$gray7D: #7d848a;
$gray9A: #9a9999;
$grayCD: #cdd4dc;
$gray92: #929eaf;
$bgF4: #f4f7fa;
$bgF0: #f0f3f5;
$gray9C: #9c9b9f;
$black: #232320;
$yellow: #ebb626;
