@import 'src/utilities/references/index';

.nfs-calculation-qr {
    padding: 32px 0 40px;
    height: 100%;
    max-width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .header {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
        position: relative;
        margin-bottom: 16px;
        .back {
            display: flex;
            align-items: center;
            gap: 6px;
            cursor: pointer;
            color: rgba(#ffffff, 0.5);
            transition: opacity 0.2s ease-in-out;
            &:hover {
                opacity: 0.5;
            }
            > p {
                font-size: 14px;
                font-weight: 400;
            }
        }
        > label {
            position: absolute;
            left: 0;
            width: 100%;
            text-align: center;
            z-index: -1;
            font-size: 14px;
            font-weight: 700;
            color: #ffffff;
        }
    }
    .content {
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 8px;
        > p {
            font-size: 12px;
            font-weight: 400;
            color: rgba(#ffffff, 0.5);
            text-align: center;
        }
        .qr-container {
            padding: 16px;
            border-radius: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 16px;
            .qr-code {
                > canvas {
                    border-radius: 12px;
                }
            }
            > h6 {
                font-size: 14px;
                font-weight: 400;
                color: #ffffff;
                text-align: center;
            }
        }
        .copy-btn {
            display: flex;
            align-items: center;
            gap: 8px;
            background-color: $gray1B;
            color: #ffffff;
            padding: 12px 16px;
            border-radius: 8px;
            transition: opacity 0.2s ease-in-out;
            cursor: pointer;
            &:hover {
                opacity: 0.8;
            }
            > p {
                font-size: 12px;
                font-weight: 500;
            }
        }
        .skeleton {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 16px;
            gap: 16px;
            .qr {
                width: 170px;
                height: 170px;
                border-radius: 12px;
            }
            .address {
                width: 300px;
                height: 20px;
                border-radius: 4px;
            }
            .btn {
                height: 40px;
                width: 100px;
                border-radius: 12px;
            }
        }
    }
}
