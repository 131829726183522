@import 'src/utilities/references/index';

.nfs-forgot-password {
    display: flex;
    flex-direction: column;
    gap: 16px;
    > p {
        font-size: 12px;
        font-weight: 400;
        color: rgba(#ffffff, 0.5);
    }
}
