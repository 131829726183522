@import 'src/utilities/references/index';

.nfs-calculation-quick-convert-coin-row {
    display: flex;
    align-items: center;
    height: 40px;
    gap: 12px;
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;
    padding: 16px 24px;
    > img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 100%;
    }
    .title {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;
        > h6 {
            font-size: 14px;
            font-weight: 500;
            color: #ffffff;
        }
        > p {
            font-size: 10px;
            font-weight: 300;
            color: rgba(#ffffff, 0.5);
        }
    }
    &:not(:last-child) {
        padding-bottom: 16px;
        border-bottom: solid 1px rgba(#ffffff, 0.05);
    }
    &:hover {
        background-color: $gray1B;
    }
}
