@import 'src/utilities/references/index';

.nfs-history-detail {
    padding: 0 24px;
    overflow: auto;
    height: 100%;
    max-height: calc(100vh - 100px);
    .general {
        .icon {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: center;
            padding-top: 34px;
            .item {
                width: 60px;
                height: 60px;
                border-radius: 100%;
                background: $gray15;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                z-index: 1;
                &:last-child {
                    margin-right: -12px;
                }
                > img {
                    width: 56px;
                    height: 56px;
                    border-radius: 100%;
                }
            }
            &.en {
                .item:last-child {
                    margin-left: -12px;
                }
            }
            &.fa {
                .item:last-child {
                    margin-right: -12px;
                }
            }
        }
        .prices {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            align-items: center;
            padding-top: 16px;
            gap: 8px;
            margin-bottom: 16px;
            > h2 {
                width: fit-content;
                font-size: 16px;
                font-weight: 600;
                display: flex;
                align-items: center;
                direction: ltr !important;
                color: #ffffff;
                &:first-child {
                    color: rgba(#ffffff, 0.5);
                }
            }
        }
        > p {
            @include NFS-size(font-size, 12px, 10px);
            font-weight: 300;
            color: rgba(#ffffff, 0.5);
            margin-bottom: 8px;
            width: 100%;
            text-align: center;
        }
    }
    .info-box {
        background-color: $gray1B;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        .item {
            overflow: hidden;
            padding: 16px;
            @include NFS-size(gap, 8px, 4px);
            display: flex;
            flex-direction: column;
            &:not(:last-child) {
                border-bottom: solid 1px rgba(#ffff, 0.05);
            }
            > label,
            p {
                font-size: 12px;
                font-weight: 400;
            }
            > label {
                color: #ffffff;
            }
            p {
                direction: ltr !important;
                color: rgba(#ffffff, 0.5);
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }
    .back-btn {
        width: 100%;
        margin: 24px 0;
    }
}
