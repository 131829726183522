@import 'src/utilities/references/index';

.nfs-forgot-otp {
    display: flex;
    flex-direction: column;
    gap: 16px;
    > p {
        font-size: 12px;
        font-weight: 400;
        color: rgba(#ffffff, 0.5);
    }
    > h6 {
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
        margin-top: 16px;
    }
    .otp-container {
        margin: 0 auto;
    }
    .otp-options {
        display: flex;
        margin: 8px auto;
        align-items: center;
        gap: 12px;
        color: #ffffff;
        > label {
            opacity: 0.5;
            cursor: default;
            &.decoration {
                cursor: pointer;
                text-decoration: underline;
            }
        }
        > * {
            font-size: 12px;
            font-weight: 300;
            cursor: pointer;
            transition: opacity 0.2s ease-in-out;
        }
        span,
        label.decoration {
            &:hover {
                opacity: 0.8;
            }
        }
    }
}
