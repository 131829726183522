@import 'src/utilities/references/index';

.nfs-header-navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    @include NFS-size(gap, 32px, 8px);
    z-index: 1;
    @include NFS-pre-lg {
        display: none;
    }
    a {
        height: 42px;
        @include NFS-size(font-size, 14px, 12px);
        justify-content: flex-start;
        font-weight: 500;
        color: #ffffff;
        display: flex;
        align-items: flex-start;
        position: relative;
        cursor: pointer;
        > * {
            transition: opacity 0.5s;
            opacity: 0.5;
        }
        .content {
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            > * {
                cursor: pointer;
            }
        }
        .underline {
            cursor: pointer;
            height: 3px;
            width: 100%;
            background: $primary;
            position: absolute;
            left: 0;
            bottom: 0;
            border-radius: 1.5px;
            opacity: 0;
        }
        &.active {
            > * {
                opacity: 1;
            }
        }
        &:hover:not(.active) {
            .content {
                opacity: 0.2 !important;
            }
        }
    }
}
