@import 'src/utilities/references/index';

.nfs-landing {
    position: relative;
    padding-bottom: 32px;
    .calculation-container {
        margin: auto;
        @include NFS-size(max-width, 532px, 310px);
        width: 100%;
        min-height: 498px;
        height: 100%;
        z-index: 2;
        position: relative;
        &.fake {
            max-height: 498px;
            border-top-left-radius: 32px;
            border-top-right-radius: 32px;
            background: linear-gradient(
                180deg,
                rgba(217, 217, 217, 0.1) -18.26%,
                rgba(21, 21, 21, 0.1) 69.84%,
                #151515 100%
            );
            z-index: 1;
            position: absolute;
            top: 64px;
            opacity: 0.5;
            @include NFS-size(max-width, 532px, 120px);
            @include NFS-pre-md {
                max-width: 202px;
            }
            &.right {
                @include NFS-size(right, -166px, -178px);
            }
            &.left {
                @include NFS-size(left, -166px, -178px);
            }
        }
    }
    .teaching {
        // width: 100%;
        padding: 0 32px;
        @include NFS-size(margin-top, 256px, 72px);
        @include NFS-size(margin-bottom, 80, 40px);
        .container {
            // width: 100%;
            margin: 0 auto;
            max-width: 1088px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 226px;
            border-radius: 16px;
            background-color: $gray1B;
            padding: 24px;
            > h4,
            > p {
                color: #ffffff;
            }
            > h4 {
                font-weight: 700;
                text-align: center;
                @include NFS-size(font-size, 20px, 14px);
            }
            > p {
                font-weight: 400;
                margin-bottom: 24px;
                text-align: center;
                opacity: 0.5;
                @include NFS-size(font-size, 14px, 12px);
                @include NFS-size(margin-top, 24px, 14px);
            }
            .watching-btn {
                display: flex;
                align-items: center;
                gap: 8px;
            }
        }
    }
    .features {
        max-width: 1088px;
        padding: 0 32px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include NFS-size(margin-top, 80px, 40px);
        > h4 {
            font-weight: 700;
            color: #ffffff;
            text-align: center;
            @include NFS-size(font-size, 20px, 16px);
        }
        > p {
            text-align: center;
            font-weight: 400;
            color: #ffffff;
            opacity: 0.5;
            @include NFS-size(font-size, 14px, 12px);
            @include NFS-size(margin-top, 24px, 12px);
            @include NFS-size(margin-bottom, 32px, 24px);
        }
        .items-container {
            width: 100%;
            display: flex;
            align-items: center;
            margin-top: 24px;
            flex-direction: row;
            @include NFS-size(gap, 32px, 48px);
            @include NFS-pre-md {
                flex-direction: column;
            }
            .item {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 16px;
                background-color: $gray1B;
                border: solid 1px rgba(#ffffff, 0.1);
                @include NFS-size(padding-left, 32px, 24px);
                @include NFS-size(padding-right, 32px, 24px);
                @include NFS-size(padding-bottom, 32px, 24px);
                @include NFS-size(padding-top, 48px, 36px);
                flex-direction: column;
                position: relative;
                color: #ffffff;
                .icon {
                    position: absolute;
                    width: 48px;
                    height: 48px;
                    @include NFS-size(width, 48px, 40px);
                    @include NFS-size(height, 48px, 40px);
                    @include NFS-size(top, -24px, -20px);
                    border-radius: 12px;
                    background: $primary;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .icon-item {
                        @include NFS-size(font-size, 24px, 20px);
                        color: #ffffff;
                    }
                }
                > h6 {
                    @include NFS-size(margin-bottom, 16px, 12px);
                    font-weight: 700;
                    @include NFS-size(font-size, 16px, 14px);
                }
                > p {
                    @include NFS-size(font-size, 12px, 12px);
                    font-weight: 400;
                    opacity: 0.5;
                    text-align: center;
                    line-height: 18px;
                }
            }
        }
    }
    .info-graph {
        max-width: 1088px;
        padding: 0 32px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-direction: row;
        @include NFS-size(margin-top, 32px, 40px);
        @include NFS-size(gap, 88px, 16px);
        &.reverse {
            flex-direction: row-reverse;
        }
        > img {
            flex: 1;
            width: 50%;
            object-fit: cover;
        }
        @include NFS-pre-md {
            flex-direction: column-reverse !important;
            img {
                width: 100%;
            }
        }
        > .text {
            display: flex;
            flex-direction: column;
            color: #ffffff;
            justify-content: center;
            height: 100%;
            > h4 {
                font-weight: 700;
                @include NFS-size(font-size, 20px, 16px);
            }
            > p {
                font-weight: 400;
                opacity: 0.5;
                @include NFS-size(font-size, 14px, 12px);
                @include NFS-size(margin-top, 24px, 12px);
                @include NFS-size(margin-bottom, 32px, 16px);
            }
            @include NFS-pre-md {
                text-align: center;
                align-items: center;
            }
            .info-graph-btn {
                max-width: 144px;
                width: 100%;
            }
        }
    }
}
