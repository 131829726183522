@import '../../assets/fonts/Dana/css/fontiran.css';
@import '../../assets/fonts/Poppins/poppins.css';

body,
h1,
h2,
h3,
h4,
h5,
h6,
input,
label,
div,
span,
button,
a,
p,
i,
ul,
li,
table,
tr,
td,
th,
tbody,
thead,
textarea {
    font-weight: 500; // Keep font-weight common
}

// Default font for Persian (Farsi)
.persian-lang {
    body,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    input,
    label,
    div,
    span,
    button,
    a,
    p,
    i,
    ul,
    li,
    table,
    tr,
    td,
    th,
    tbody,
    thead,
    textarea {
        font-weight: 500; // Keep font-weight common
        font-family: dana !important;
    }
}

// Font for English
.english-lang {
    body,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    input,
    label,
    div,
    span,
    button,
    a,
    p,
    i,
    ul,
    li,
    table,
    tr,
    td,
    th,
    tbody,
    thead,
    textarea {
        font-weight: 500; // Keep font-weight common
        font-family: Poppins !important;
    }
}
