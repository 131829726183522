@import 'src/utilities/references/index';

.nfs-header-mobile-navbar {
    &-backdrop {
        position: absolute;
        left: 100%;
        top: 0;
        background-color: rgba(#000000, 0.5);
        width: 100%;
        height: 100%;
        z-index: 1000;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        &.active {
            left: 0;
            opacity: 1;
        }
    }
    &-container {
        position: absolute;
        max-width: 320px;
        top: 0;
        z-index: 10000;
        background-color: $gray1B;
        height: 100vh;
        padding: 24px 40px 48px;
        &-content {
            overflow: auto;
            width: 100%;
            height: calc(100vh - 78px);
        }
        .spacer {
            flex: 1;
            min-height: 32px;
        }
        &.fa {
            left: 100vw;
            transition: left 0.2s ease-in-out;
            border-top-left-radius: 16px;
            border-top-right-radius: 0px;
            &.active {
                left: calc(100vw - 320px);
            }
        }
        &.en {
            right: 100%;
            transition: right 0.2s ease-in-out;
            border-top-left-radius: 0;
            border-top-right-radius: 16px;
            &.active {
                right: calc(100vw - 292px);
            }
        }
        display: flex;
        flex-direction: column;
        .header {
            .back-icon {
                cursor: pointer;
                transition: opacity 0.2s ease-in-out;
                &:hover {
                    opacity: 0.8;
                }
            }
            display: flex;
            width: 100%;
            align-items: center;
            margin-bottom: 24px;
            .change-lang-container {
                width: 48px;
                height: 48px;
                border-radius: 12px;
                border: 1px solid rgba(#ffffff, 0.1);
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $gray1B;
                cursor: pointer;
                transition: background-color 0.2s;
                &:hover {
                    background-color: rgba(#ffffff, 0.05);
                }
                img {
                    width: 24px;
                    height: 24px;
                }
            }
        }
        .items-container {
            display: flex;
            flex-direction: column;
            gap: 32px;
            a {
                font-size: 16px;
                font-weight: 500;
                color: #ffffff;
                opacity: 0.5;
                &.active {
                    opacity: 1;
                }
            }
        }
        .register-btn {
            margin-top: 24px;
            max-width: 160px;
        }
        .contact-us {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin: 32px 0;
            color: #ffffff;
            > h4 {
                font-size: 14px;
                font-weight: 700;
            }
            > p {
                font-size: 12px;
                font-weight: 500;
                display: flex;
                align-items: center;
                gap: 6px;
            }
        }
        .socials {
            display: flex;
            align-items: center;
            width: 100%;
            gap: 32px;
            > * {
                cursor: pointer;
                color: #ffffff;
                display: flex;
                align-items: center;
                @include NFS-size(font-size, 20px, 26px);
            }
        }
    }
}
.MuiPopover-root {
    z-index: 130000000 !important;
}
