@import 'src/utilities/references/index';

.nfs-header {
    // position: sticky;
    // top: 0;
    // background-color: $background;
    // z-index: 10;
    position: relative;
    .container {
        max-width: 1088px;
        margin: 0 auto;
        padding: 0 40px;
        display: flex;
        height: 128px;
        align-items: center;
        position: relative;
        .right-section {
            z-index: 2;
            display: flex;
            gap: 12px;
            .mobile-menu {
                display: none;
                cursor: pointer;
                transition: opacity 0.2s ease-in-out;
                &:hover {
                    opacity: 0.8;
                }
            }
            .change-lang-container {
                width: 48px;
                height: 48px;
                border-radius: 12px;
                border: 1px solid rgba(#ffffff, 0.1);
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $gray1B;
                cursor: pointer;
                transition: background-color 0.2s;
                &:hover {
                    background-color: rgba(#ffffff, 0.05);
                }
                img {
                    width: 24px;
                    height: 24px;
                }
            }
            @include NFS-pre-lg {
                .register-btn,
                .change-lang-container {
                    display: none;
                }
                .mobile-menu {
                    display: block;
                }
            }
        }
        .logo {
            width: 105px;
            height: 32px;
            z-index: 2;
            cursor: pointer;
        }
        .space {
            flex: 1;
        }
    }
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
    background-color: unset !important;
    margin-top: 8px;
    border-radius: 12px;
}
.change-lang-popover {
    background-color: $gray1B;
    width: 104px;
    border-radius: 12px;
    border: solid 1px rgba(#ffffff, 0.1);
    padding: 6px 4px;
    gap: 6px;
    display: flex;
    flex-direction: column;
    &.account {
        width: fit-content;
        max-width: 250px;
    }
    .item {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
        label {
            cursor: pointer;
            font-size: 12px;
            font-weight: 500;
            color: #ffffff;
        }
        cursor: pointer;
        border-radius: 8px;
        padding: 6px 8px;
        transition: background-color 0.3s ease-in-out;
        img {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
        &.selected {
            background-color: rgba(#ffffff, 0.05);
        }
        &:hover {
            background-color: rgba(#ffffff, 0.1);
        }
    }
    .info {
        display: flex;
        flex-direction: column;
        color: #ffffff;
        padding: 6px 8px 0;
        gap: 6px;
        > label {
            font-size: 13px;
            font-weight: 400;
        }
        > p {
            font-size: 13px;
            font-weight: 300;
            opacity: 0.5;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            direction: ltr;
        }
    }
}
