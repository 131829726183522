@import 'src/utilities/references/index';

.nfs-footer {
    width: 100%;
    .container {
        display: flex;
        flex-direction: column;
        .top {
            background-color: $gray1B;
            .container {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 40px 32px;
                max-width: 1088px;
                margin: 0 auto;
                @include NFS-size(gap, 64px, 48px);
                @include NFS-pre-md {
                    flex-direction: column;
                }

                .info {
                    flex: 2.5;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    > img {
                        width: 130px;
                        height: 40px;
                        cursor: pointer;
                    }
                    > p {
                        @include NFS-size(font-size, 14px, 10px);
                        font-weight: 400;
                        color: rgba(#ffffff, 0.5);
                        &:nth-child(2) {
                            margin: 24px 0;
                        }
                    }
                    @include NFS-pre-md {
                        align-items: center;
                        > p {
                            text-align: center;
                            margin: 16px 0 0 !important;
                            &:last-child {
                                display: none;
                            }
                        }
                    }
                }
                .links {
                    flex: 1;
                    display: flex;
                    flex-direction: row;
                    gap: 64px;
                    @include NFS-pre-md {
                        justify-content: space-around;
                        text-align: center;
                    }

                    .item {
                        display: flex;
                        flex-direction: column;
                        color: #ffffff;
                        label {
                            font-size: 16px;
                            font-weight: 700;
                            @include NFS-size(font-size, 16px, 14px);
                            margin-bottom: 16px;
                        }
                        > a {
                            color: #ffffff;
                            font-weight: 400;
                            opacity: 0.5;
                            text-align: center;
                            &:not(&:last-child) {
                                margin-bottom: 8px;
                            }
                            @include NFS-size(font-size, 16px, 14px);
                        }
                    }
                }
            }
        }
        .bottom {
            background: $primary;
            .container {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 56px;
                max-width: 1088px;
                margin: 0 auto;
                padding: 0 32px;
                justify-content: space-between;
                color: #ffffff;
                font-size: 14px;
                font-weight: 500;
                @include NFS-size(font-size, 14px, 12px);
                @include NFS-pre-md {
                    flex-direction: column;
                    height: unset;
                    padding: 24px;
                    gap: 12px;
                    text-align: center;
                }
                .email {
                    display: flex;
                    flex-direction: row-reverse;
                    gap: 8px;
                    align-items: center;
                    cursor: pointer;
                    color: #ffffff;
                    .icon {
                        font-size: 20px;
                    }
                    > * {
                        cursor: pointer;
                    }
                    @include NFS-pre-md {
                        padding-bottom: 12px;
                    }
                }
                .socials {
                    display: flex;
                    gap: 32px;
                    > * {
                        cursor: pointer;
                        color: #ffffff;
                        display: flex;
                        align-items: center;
                        @include NFS-size(font-size, 20px, 26px);
                    }
                }
            }
        }
    }
}
