@import 'src/utilities/references/index';

.nfs-button {
    height: 48px;
    min-height: 48px;
    max-height: 48px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 12px;
    border: none !important;
    outline: none !important;
    overflow: hidden;
    padding: 0 24px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: opacity 0.18s;
    &:hover {
        opacity: 0.9;
    }

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &.button-loading {
        pointer-events: none;
    }

    &.primary {
        background: $primary;
        color: #ffffff;
    }

    &.secondary {
        background: white;
        color: $gray15;
    }

    &.text {
        background: transparent;
        color: rgba(#ffffff, 0.5);
    }

    &.outline {
        background-color: transparent;
        color: #ffffff;
        border: solid 1px #ffffff !important;
        height: 40px;
        min-height: 40px;
        max-height: 40px;
        font-size: 12px;
        font-weight: 500;
    }

    .button-loader {
        background-color: rgba(#000000, 0.6);
        cursor: progress;
        position: absolute;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        opacity: 0;
        animation: fadeIn 0.3s ease-in-out;
        animation-fill-mode: forwards;
    }
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}
