@import 'src/utilities/references/index';

.nfs-calculation {
    width: 100%;
    height: 100%;
    position: relative;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    background: linear-gradient(180deg, rgba(217, 217, 217, 0.1) -18.26%, rgba(21, 21, 21, 0.1) 69.84%, #151515 100%);
    .calculation-page {
        &-loading {
            > * {
                background: $primary;
            }
        }
        &-error {
            width: 100%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            font-weight: 400;
            color: rgba(#ffffff, 0.5);
        }
    }
}
