@font-face {
    font-family: Poppins;
    font-style: normal;
    font-weight: 100;
    src: url('./Poppins-Thin.ttf') format('truetype');
}

@font-face {
    font-family: Poppins;
    font-style: italic;
    font-weight: 100;
    src: url('./Poppins-ThinItalic.ttf') format('truetype');
}

@font-face {
    font-family: Poppins;
    font-style: normal;
    font-weight: 200;
    src: url('./Poppins-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: Poppins;
    font-style: italic;
    font-weight: 200;
    src: url('./Poppins-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    src: url('./Poppins-Light.ttf') format('truetype');
}

@font-face {
    font-family: Poppins;
    font-style: italic;
    font-weight: 300;
    src: url('./Poppins-LightItalic.ttf') format('truetype');
}

@font-face {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    src: url('./Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    src: url('./Poppins-Medium.ttf') format('truetype');
}

@font-face {
    font-family: Poppins;
    font-style: italic;
    font-weight: 500;
    src: url('./Poppins-MediumItalic.ttf') format('truetype');
}

@font-face {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    src: url('./Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: Poppins;
    font-style: italic;
    font-weight: 600;
    src: url('./Poppins-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    src: url('./Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: Poppins;
    font-style: italic;
    font-weight: 700;
    src: url('./Poppins-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: Poppins;
    font-style: normal;
    font-weight: 800;
    src: url('./Poppins-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: Poppins;
    font-style: italic;
    font-weight: 800;
    src: url('./Poppins-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: Poppins;
    font-style: normal;
    font-weight: 900;
    src: url('./Poppins-Black.ttf') format('truetype');
}

@font-face {
    font-family: Poppins;
    font-style: italic;
    font-weight: 900;
    src: url('./Poppins-BlackItalic.ttf') format('truetype');
}
