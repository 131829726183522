@import 'src/utilities/references/index';

.add-wallet-modal {
    .nfs-modal-body {
        width: 90%;
        margin: 0 auto;

        .nfs-input {
            margin-top: 16px;
        }

        .nfs-button {
            margin: 24px auto;
            width: 100%;
        }
    }
}

.nfs-calculation-voucher {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .header {
        display: flex;
        align-items: center;
        width: 90%;
        position: relative;
        margin: 32px auto 16px;

        .back {
            display: flex;
            align-items: center;
            gap: 6px;
            cursor: pointer;
            color: rgba(#ffffff, 0.5);
            transition: opacity 0.2s ease-in-out;

            &:hover {
                opacity: 0.5;
            }

            > p {
                font-size: 14px;
                font-weight: 400;
            }
        }

        > label {
            width: 100%;
            font-size: 14px;
            font-weight: 700;
            color: #ffffff;
            flex: 1;
        }
    }

    .content {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        width: 90%;

        > p {
            font-size: 12px;
            font-weight: 400;
            color: rgba(#ffffff, 0.5);
            margin-bottom: 24px;
        }

        .voucher-input {
            &:not(:nth-child(4)) {
                margin-bottom: 16px;
            }
        }

        .add-wallet {
            display: flex;
            align-items: center;
            margin-top: 20px;
            gap: 12px;
            opacity: 1;
            transition: opacity 0.2s ease-in-out;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }

            &.disabled {
                opacity: 0.5;
            }

            .plus {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                border-radius: 8px;
                background: $primary;

                > .icon {
                    font-size: 16px;
                    color: #ffffff;
                }
            }

            > label {
                font-size: 12px;
                font-weight: 400;
                color: #ffffff;
                cursor: pointer;
            }
        }
    }

    .saved-wallets-title {
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        align-self: flex-start;
        margin-top: 24px;
        width: 90%;
        margin-inline: auto;
    }

    .pulse-loader-icon {
        display: flex;
        align-items: center;
        height: 64px;
        > * {
            background: $primary;
        }
    }

    .empty-state {
        display: flex;
        align-items: center;
        height: 64px;
        text-align: center;
        justify-content: center;
        font-size: 12px;
        color: #ffffff;
        opacity: 0.5;
    }

    .saved-wallets-swiper {
        @include NFS-pre-md {
            display: none;
        }

        width: 90%;
        display: flex;
        margin-top: 16px;

        .saved-wallet {
            display: flex;
            background-color: $gray1B;
            border-radius: 12px;
            padding: 16px;
            box-sizing: border-box;
            align-items: center;
            gap: 12px;
            cursor: pointer;
            transition: opacity 0.2s ease-in-out;
            width: max-content;

            &:hover {
                opacity: 0.8;
            }

            .icon-container {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                min-width: 32px;
                min-height: 32px;
                background: $primary;

                svg {
                    color: #ffffff;
                }
            }

            .wallet-info {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                gap: 8px;
                overflow: hidden;

                span {
                    &:first-child {
                        font-size: 12px;
                        font-weight: 600;
                        color: #ffffff;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 100%;
                        max-width: 100%;
                    }

                    &:last-child {
                        font-size: 10px;
                        font-weight: 300;
                        color: #ffffff;
                        opacity: 0.5;
                    }
                }
            }
        }
    }

    .saved-wallets-list {
        @include NFS-md {
            display: none;
        }

        list-style: none;
        padding: unset;
        display: flex;
        flex-direction: column;
        width: 90%;
        margin-inline: auto;
        margin-top: 12px;
        gap: 10px;

        .saved-wallet {
            display: flex;
            background-color: $gray1B;
            border-radius: 12px;
            padding: 16px;
            box-sizing: border-box;
            align-items: center;
            gap: 12px;
            cursor: pointer;
            transition: opacity 0.2s ease-in-out;

            &:hover {
                opacity: 0.8;
            }

            .icon-container {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                width: 32px;
                height: 32px;
                background: $primary;

                svg {
                    color: #ffffff;
                }
            }

            .wallet-info {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                gap: 8px;

                span {
                    &:first-child {
                        font-size: 10px;
                        font-weight: 600;
                        color: #ffffff;
                    }

                    &:last-child {
                        font-size: 10px;
                        font-weight: 300;
                        color: #ffffff;
                        opacity: 0.5;
                    }
                }
            }
        }
    }

    .submit-button {
        width: 90%;
        margin: 16px auto;
    }
}
