@import '../references/index';

* {
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    &::-webkit-scrollbar:hover {
        width: 10px;
        height: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background-color: #dddbdd;
        // box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background-color: #757575;
        border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        width: 50px;
        background-color: #999999;
    }

    .nfs-skeleton {
        background-color: #424242; /* Darker background for dark mode */
        display: block;
        position: relative;
        overflow: hidden;

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transform: translateX(-100%);
            background-image: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 0,
                rgba(255, 255, 255, 0.2) 20%,
                /* Light gradient for visibility */ rgba(255, 255, 255, 0.5) 60%,
                rgba(255, 255, 255, 0) /* Transparent end */
            );
            animation: skeleton-background-animation 2s infinite;
            content: '';
        }

        @keyframes skeleton-background-animation {
            100% {
                transform: translateX(100%);
            }
        }
    }
}

.un-styled-button,
.un-styled-button:hover,
.un-styled-button:active,
.un-styled-button:focus {
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0;
}

// * checkbox *

.NFS-checkbox {
    display: flex;
    align-items: flex-start;
    cursor: pointer;

    * {
        box-sizing: border-box;

        &::after,
        &::before {
            box-sizing: border-box;
        }
    }

    input[type='checkbox'],
    input[type='radio'] {
        margin: 0;
        outline: 0;
        min-width: 15px;
        height: 15px;
        cursor: pointer;
        appearance: none;
        border-radius: 4px;
        position: relative;
        border: 1px solid $grayAE;
        transition: background-color 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
        background-color: white;
        margin-left: 5px;

        @include ltr {
            margin-left: unset;
            margin-right: 5px;
        }

        &::before {
            top: 0px;
            left: 4px;
            width: 5px;
            opacity: 0;
            content: '';
            height: 10px;
            display: block;
            position: absolute;
            border-style: solid;
            border-color: #fff;
            transform: rotate(45deg);
            border-width: 0 3px 3px 0;
        }

        &:checked {
            color: white;
            background: $primary;
            border-color: $primary;

            &::before {
                opacity: 1;
            }
        }
    }

    label {
        color: $gray89;
        padding: 0 6px;
        cursor: pointer;
        font-size: 15px;
    }
}

// * radio button *

.NFS-radio {
    display: flex;
    align-items: flex-start;

    * {
        box-sizing: border-box;

        &::after,
        &::before {
            box-sizing: border-box;
        }
    }

    input[type='radio'],
    input[type='checkbox'] {
        margin: 0;
        outline: 0;
        min-width: 18px;
        height: 18px;
        cursor: pointer;
        appearance: none;
        border-radius: 4px;
        position: relative;
        border: 1px solid $grayAE;
        transition: background-color 175ms cubic-bezier(0.1, 0.1, 0.25, 1);

        &::before {
            top: -1px;
            left: 0px;
            width: 15px;
            opacity: 0;
            content: '';
            height: 15px;
            display: block;
            border-radius: 50%;
            position: absolute;
            background-color: $blue3E;
            border: white 3px solid;
        }

        &:checked {
            color: white;
            background: $primary;
            border-color: $primary;

            &::before {
                opacity: 1;
            }
        }
    }

    label {
        color: $gray89;
        padding: 0 6px;
        cursor: pointer;
        font-size: 15px;
    }
}

// * range slider *
.rangeslider {
    height: 7px;
    box-shadow: none;
    background-color: $grayD9;
    margin: 14px 0;
    cursor: pointer;

    .rangeslider__fill {
        background-color: $primary;
        box-shadow: none;
        margin-right: auto;
        @include rtl {
            margin-right: unset;
        }
    }
    .rangeslider__handle-tooltip {
        width: unset;
        padding: 0 6px;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(black, 0.2);
        color: $navy3F;
        &::after {
            border-top: 8px solid rgba(white, 0.8);
        }
    }
    .rangeslider__handle {
        width: 18px;
        height: 18px;
        border: 3px solid white;
        margin-right: -18px;
        background-color: $primary;
        outline: none;
        @include rtl {
        }
        &::after {
            content: none;
        }
    }
}

// bootstrap dropdown

.dropdown-menu {
    min-width: 8rem !important;
    &.show {
        a {
            font-size: 12px;
            padding: 6px 12px !important;
            color: $navy49;
            display: flex;
            align-items: center;
            span {
                padding-left: 6px;
                color: $grayAE;
                font-size: 18px;
                @include ltr {
                    padding: 0 6px 0 0;
                }
            }
        }
    }
}

/* premium icon */

.premium-star {
    box-sizing: border-box;
    position: relative;
    width: 14px;
    height: 10px;
    border: 2px solid $yellow;
    border-top-color: transparent;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    padding-top: 4px;
    margin: 0 4px;
}
.premium-star::after,
.premium-star::before {
    content: '';
    display: block;
    position: absolute;
    box-sizing: border-box;
    width: 6px;
    height: 6px;
    border-bottom: 2px solid $yellow;
    border-left: 2px solid $yellow;
    transform: rotate(-45deg);
    top: -4px;
}

.premium-star::after {
    border-bottom-right-radius: 2px;
    right: -1px;
}

.premium-star::before {
    border-top-left-radius: 2px;
    left: -1px;
}
