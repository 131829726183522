@import '../../references/index';

.nfs-otp {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    direction: ltr !important;
    input {
        outline: none;
        width: 48px;
        height: 48px;
        font-size: 24px;
        font-weight: 700;
        line-height: 1.5;
        border-radius: 8px;
        text-align: center;
        color: #ffffff;
        background: $gray1B;
        transition:
            opacity 0.2s ease-in-out,
            border-color 0.2s ease-in-out;
        border: solid 1px rgba(#ffffff, 0.1);
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px $gray1B inset !important;
        }

        &:-webkit-autofill {
            -webkit-text-fill-color: #ffffff !important;
        }

        &:hover {
            opacity: 0.8;
        }

        &:focus {
            border: 1px solid rgba(#ffffff, 0.1);
        }

        // firefox
        &:focus-visible {
            outline: 0;
        }
    }
    .base--focused {
        input {
            border-color: rgba(#ffffff, 0.5);
        }
    }
}
