@import '../../../../utilities/references/index';

.nfs-result-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: $greenSuccess;
    margin-top: 40px;

    @include NFS-pre-md {
      width: 32px;
      height: 32px;
    }

    svg {
      width: 50%;
      height: 50%;
    }
  }

  h4 {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
  }

  p {
    margin-top: 12px;
    font-size: 12px;
    color: #ffffff;
    opacity: 0.5;
    font-weight: 300;
  }

  .content {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    border-radius: 12px;
    background-color: $gray1B;
    margin-inline: auto;
    width: 75%;

    > div {
      padding: 16px;
    }

    .tx-id-container {
      display: flex;
      flex-direction: column;
      gap: 8px;

      label {
        font-size: 12px;
        color: #ffffff;
        opacity: 0.5;
        font-weight: 400;
      }

      span {
        color: #ffffff;
        font-size: 12px;
        font-weight: 400;
      }
    }

    hr {
      width: 100%;
      border-width: 0.5px;
      margin: 0 auto;
      opacity: 0.05;
    }

    .amount-container {
      display: flex;
      justify-content: space-between;

      label {
        font-size: 12px;
        color: #ffffff;
        opacity: 0.5;
        font-weight: 400;
      }

      .amounts {
        display: flex;
        flex-direction: column;
        gap: 8px;
        direction: ltr;

        span {
          &:first-child {
            font-size: 12px;
            font-weight: 600;
            color: #ffffff;
          }

          &:last-child {
            font-size: 10px;
            font-weight: 300;
            color: #ffffff;
            opacity: 0.5;
          }
        }
      }
    }
  }

  .nfs-button {
    margin-top: 24px;

    @include NFS-pre-md {
      width: 75%;
    }
  }
}