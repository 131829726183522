@import 'src/utilities/references/index';

.nfs-history-skeleton {
    min-width: 340px;
    height: 196px;
    flex: 1;
    background-color: $gray1B;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    .spacer {
        flex: 1;
    }
    .header {
        display: flex;
        flex-direction: column;
        .content {
            padding: 16px;
            display: flex;
            align-items: center;
            padding-bottom: 16px;
            width: calc(100% - 32px);
            padding: 0 16px 16px;
            .name {
                display: flex;
                align-items: center;
                gap: 8px;
                .title {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    .item {
                        width: 46px;
                        height: 12px;
                        border-radius: 4px;
                    }
                }
                .icon {
                    display: flex;
                    .item {
                        width: 32px;
                        height: 32px;
                        border-radius: 100%;
                        background: $gray1B;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        z-index: 1;
                        &:last-child {
                            margin-right: -12px;
                        }
                        > div {
                            width: 28px;
                            height: 28px;
                            border-radius: 100%;
                        }
                    }
                }
            }
            .button {
                width: 94px;
                height: 34px;
                border-radius: 8px;
            }
        }
        .line {
            width: 100%;
            height: 3px;
            background: radial-gradient(11px 20px, rgb(255 255 255 / 10%) 69%, transparent 87%) 0 0 / 36px 100%;
        }
    }
    .content {
        padding: 16px 16px 0;
        height: 100%;
        display: flex;
        justify-content: space-between;
        .column {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            .item {
                display: flex;
                flex-direction: column;
                gap: 12px;
                > div {
                    width: 60px;
                    height: 16px;
                    border-radius: 4px;
                }
            }
            .button {
                width: 100px;
                height: 40px;
                border-radius: 12px;
            }
            &:last-child {
                .item {
                    > div {
                        width: 100%;
                    }
                }
            }
        }
    }
}
