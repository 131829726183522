@import 'src/utilities/references/index';

.nfs-history {
    max-width: 1088px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 424px);
    .header {
        display: flex;
        align-items: center;
        .spacer {
            flex: 1;
        }
        .filter {
            display: flex;
            align-items: center;
            gap: 36px;
            &-item {
                height: 42px;
                font-size: 14px;
                justify-content: flex-start;
                font-weight: 500;
                color: #ffffff;
                display: flex;
                align-items: flex-start;
                position: relative;
                cursor: pointer;
                > * {
                    transition: opacity 0.5s;
                    opacity: 0.5;
                }
                .content {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    cursor: pointer;
                    > * {
                        cursor: pointer;
                    }
                }
                .underline {
                    cursor: pointer;
                    height: 3px;
                    width: 100%;
                    background: $primary;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    border-radius: 1.5px;
                    opacity: 0;
                }
                &.active {
                    > * {
                        opacity: 1;
                    }
                }
                &:hover:not(.active) {
                    .content {
                        opacity: 0.2 !important;
                    }
                }
            }
        }
        .search-container {
            display: flex;
            align-items: center;
            gap: 16px;
            .search {
                &-input {
                    max-width: 200px;
                    min-width: 200px;
                }
                &-btn {
                    max-width: 90px;
                }
            }
        }
        @include NFS-pre-md {
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            .search-container {
                width: 100%;
                .search-input {
                    max-width: unset;
                }
            }
        }
    }
    .data-container {
        flex: 1;
        display: flex;
        padding: 32px 0;
        .safe-grid {
            min-width: 348px;
            flex: 1;
        }
        .empty-state {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            color: rgba(#ffffff, 0.5);
        }
        .grid-container {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 24px;
        }
    }
    .pagination {
        display: flex;
        width: 100%;
        align-items: center;
        color: #ffffff;
        margin-bottom: 64px;
        @include NFS-pre-md {
            gap: 12px;
            flex-direction: column;
        }
        .spacer {
            flex: 1;
        }
        p {
            font-size: 12px;
            font-weight: 400;
        }
        &-container {
            direction: ltr !important;
            > nav {
                > ul {
                    > li {
                        > div {
                            color: #ffffff;
                        }
                        > button {
                            background-color: $gray1B;
                            color: rgba(#ffffff, 0.5);
                            &.Mui-selected {
                                background-color: #ffffff;
                                color: $gray15;
                            }
                        }
                    }
                }
            }
        }
    }
}
