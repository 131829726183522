@import 'src/utilities/references/index';

.nfs-authentication {
    padding: 32px 0 40px;
    width: 90%;
    margin: auto;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    .header {
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;
        margin-bottom: 16px;
        .back {
            display: flex;
            align-items: center;
            gap: 6px;
            cursor: pointer;
            color: rgba(#ffffff, 0.5);
            transition: opacity 0.2s ease-in-out;
            &:hover {
                opacity: 0.5;
            }
            > p {
                font-size: 14px;
                font-weight: 400;
            }
        }
        > label {
            width: 100%;
            font-size: 14px;
            font-weight: 700;
            color: #ffffff;
            flex: 1;
        }
    }
    .content {
        width: 100%;
        margin: 0 auto;
        .options {
            margin-top: 24px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            font-size: 12px;
            font-weight: 300;
            color: #ffffff;

            > label {
                opacity: 0.5;
                cursor: pointer;
            }
            > * {
                cursor: pointer;
                transition: opacity 0.2s ease-in-out;
                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
}
