@import 'src/utilities/references/index';

.nfs-calculation {
    &-loading {
        width: 100%;
        height: 100%;
        min-height: 498px;
        display: flex;
        align-items: center;
        justify-content: center;
        > .icon {
            > * {
                background: $primary;
            }
        }
    }
    &-quick-convert {
        padding: 40px 16px;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        .title {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 24px;
            > h4 {
                font-size: 20px;
                font-weight: 700;
                color: #ffffff;
                @include NFS-size(font-size, 20px, 14px);
            }
            .tooltip {
                align-items: center;
                &.desktop {
                    display: flex;
                }
                &.mobile {
                    display: none;
                }
                @include NFS-pre-lg {
                    &.mobile {
                        display: flex;
                    }
                    &.desktop {
                        display: none;
                    }
                }

                > .icon {
                    @include NFS-size(min-width, 24px, 16px);
                    @include NFS-size(max-width, 24px, 16px);
                    @include NFS-size(min-height, 24px, 16px);
                    @include NFS-size(min-height, 24px, 16px);
                    width: 100%;
                    height: 100%;
                    color: $gray70;
                    cursor: pointer;
                    transition: opacity 0.2s ease-in-out;
                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
        .boxes-container {
            .box {
                border-radius: 16px;
                border: solid 1px rgba(#ffffff, 0.1);
                padding: 16px 0 12px;
                &:nth-child(1) {
                    padding-bottom: 16px;
                }
                color: #ffffff;
                gap: 18px;
                display: flex;
                flex-direction: column;
                > label {
                    font-weight: 500;
                    padding: 0 24px;
                    @include NFS-size(font-size, 12px, 10px);
                }
                .value {
                    display: flex;
                    align-items: center;
                    padding: 0 24px;
                    > input {
                        background-color: transparent;
                        border: none;
                        outline: none;
                        color: #ffffff;
                        font-weight: 500;
                        width: 100%;
                        z-index: 1;
                        &::-webkit-outer-spin-button,
                        &::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                        @include NFS-size(font-size, 20px, 16px);
                    }
                    > .spacer {
                        flex: 1;
                    }
                    .coin {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        > img {
                            @include NFS-size(min-width, 32px, 24px);
                            @include NFS-size(max-width, 32px, 24px);
                            @include NFS-size(min-height, 32px, 24px);
                            @include NFS-size(min-height, 32px, 24px);
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 100%;
                            overflow: hidden;
                        }
                        > p {
                            font-size: 16px;
                            font-weight: 500;
                            color: #ffffff;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                        cursor: pointer;
                        transition: opacity 0.2s ease-in-out;
                        :nth-child(1) {
                            color: rgba(#ffffff, 0.5);
                        }
                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }
                .fee {
                    border-top: solid 1px rgba(#ffffff, 0.1);
                    padding: 12px 24px 0;
                    display: flex;
                    align-items: center;
                    .spacer {
                        flex: 1;
                    }
                    > p {
                        color: $gray99;
                        font-size: 10px;
                        font-weight: 300;
                        &:nth-child(1) {
                            font-weight: 500;
                        }
                    }
                }
            }
            .swap-icon {
                border: solid 1px $gray36;
                @include NFS-size(width, 40px, 32px);
                @include NFS-size(height, 40px, 32px);
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                transform: rotate(90deg);
                margin: -12px auto;
                background-color: $gray1B;
                transition: opacity 0.2s ease-in-out;
                cursor: pointer;
                &:hover {
                    opacity: 0.8;
                }
                > * {
                    color: #999999;
                    @include NFS-size(font-size, 32px, 24px);
                }
                &.disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                }
            }
            .btn {
                width: 100%;
                margin-top: 30px;
            }
        }
    }
}
