@import '../../references/index';

.nfs-input {
    cursor: text;
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 1;
    position: relative;

    > label {
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 12px;
    }

    > div {
        height: 48px;
        display: flex;
        transition: 0.2s;
        border-radius: 12px;
        align-items: center;
        border: solid 1px rgba(#ffffff, 0.1);
        transition: border-color 0.2s ease-in-out;
        background-color: $gray1B;
        overflow: hidden;

        @include ltr {
            padding: 0 12px 0 18px;
        }

        @include rtl {
            padding: 0 18px 0 12px;
        }

        > input {
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
            background-color: inherit;
            @include NFS-size(font-size, 12px, 16px);
            font-weight: 400;
            color: #ffffff;
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
                -webkit-box-shadow: 0 0 0 30px #1b1b1b inset !important;
            }

            &:-webkit-autofill {
                -webkit-text-fill-color: white !important;
            }
            &::placeholder {
                color: rgba(#ffffff, 0.35);
                font-size: 12px;
                font-weight: 500;
            }
        }

        .end-icon {
            display: flex;
            align-items: center;
            color: $blue;
            gap: 10px;
            label {
                font-size: 12px;
                font-weight: 500;
                white-space: nowrap;
            }
            .icon {
                min-width: 20px;
                min-height: 20px;
                max-width: 20px;
                max-height: 20px;
                width: 100%;
                height: 100%;
            }

            &.active {
                &,
                label {
                    cursor: pointer;
                    transition: opacity 0.2s ease-in-out;
                    &:hover {
                        opacity: 0.9;
                    }
                }
            }
        }
    }

    &.filled > div {
        input[type='email'],
        input[type='password'] {
            text-align: left;
            direction: ltr;
        }
    }

    &.active {
        > div {
            border-color: rgba(#ffffff, 0.5);
        }
    }

    &.error {
        > div {
            border: solid 1px $red;
        }
    }

    p.input-error {
        text-align: inherit;
        padding: 0 13px;
        margin-top: 6px;
        font-size: 10px !important;
        color: $red !important;
        transition: 0.2s;
    }

    p.input-hint {
        font-size: 10px;
        margin: -6px 0 6px;
        padding: 0 6px;
        color: #b8b8b8;
    }

    .required-star {
        font-size: 14px;
        color: $red;
        padding: 0 0 0 6px;
        @include rtl {
            padding: 0 6px 0 0;
        }
    }

    &.disabled {
        cursor: auto;
        .end-icon {
            color: $grayB4;
        }
    }
}
