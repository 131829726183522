@import 'src/utilities/references/index';

.nfs-swiper {
    direction: ltr !important;
    margin: 24px auto;
    .swiper-slide {
        border-radius: 8px;
    }
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 8px;
        overflow: hidden;
    }
    .swiper-pagination {
        margin-top: 20px;
        position: relative !important;
        &-bullet {
            background-color: #ffffff;
            &-active {
                background-color: #ffffff;
            }
        }
    }
}
