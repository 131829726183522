@import 'src/utilities/references/index';

.nfs-landing-steps {
    max-width: 1088px;
    padding: 48px 16px 0;
    margin: 0 auto;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    > h4 {
        font-weight: 700;
        text-align: center;
        @include NFS-size(font-size, 20px, 16px);
    }
    > p {
        @include NFS-size(font-size, 14px, 12px);
        @include NFS-size(margin-top, 24px, 12px);
        @include NFS-size(margin-bottom, 32px, 24px);
        font-weight: 400;
        opacity: 0.5;
        text-align: center;
    }
    .steps-container {
        display: flex;
        align-items: center;
        flex-direction: row;
        @include NFS-pre-md {
            flex-direction: column;
        }
        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            .shape {
                .square {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 20px;
                    font-size: 20px;
                    font-weight: 700;
                    &.one {
                        width: 90px;
                        height: 90px;
                        background: linear-gradient(90deg, rgba(229, 4, 45, 0.2) 0%, rgba(153, 0, 34, 0.2) 100%);
                    }
                    &.two {
                        width: 68px;
                        height: 68px;
                        background: linear-gradient(90deg, rgba(229, 4, 45, 0.5) 0%, rgba(153, 0, 34, 0.5) 100%);
                    }
                    &.three {
                        width: 48px;
                        height: 48px;
                        background: linear-gradient(90deg, #e5042d 0%, #990022 100%);
                    }
                }
            }
            .text {
                width: max-content;
                top: 114px;
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: #ffffff;
                max-width: 228px;
                @include NFS-md {
                    @media (max-width: 1220px) {
                        max-width: 110px;
                    }
                    @media (max-width: 1000px) {
                        max-width: 100px;
                    }
                }
                @include NFS-pre-md {
                    position: relative;
                    top: unset;
                    margin-top: 16px;
                }
                h6 {
                    font-weight: 700;
                    text-align: center;
                    @include NFS-size(font-size, 16px, 12px);
                }
                p {
                    font-weight: 400;
                    text-align: center;
                    @include NFS-size(font-size, 12px, 10px);
                    @include NFS-size(margin-top, 16px, 12px);
                }
            }
        }
        .line {
            flex: 1;
            height: 1px;
            background: linear-gradient(90deg, rgba(217, 217, 217, 0) 0%, #ffffff 50.5%, rgba(221, 153, 221, 0) 100%);
            @include NFS-pre-md {
                min-height: 90px;
                width: 1px;
                margin: 6px 0;
                background: linear-gradient(
                    0deg,
                    rgba(217, 217, 217, 0) 0%,
                    #ffffff 50.5%,
                    rgba(221, 153, 221, 0) 100%
                );
            }
        }
    }
}
