.rc-mentions {
    textarea {
        width: 100%;
        height: 150px !important;
        max-height: 150px !important;
        border-radius: 12px;
        border: solid 1px #ced4da !important;
        font-size: 14px;
        padding: 12px 24px;
        outline: none;

        &:focus {
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }

        &::placeholder {
            color: $grayAE;
            font-size: 14px;
            font-weight: 500;
        }
    }
}

textarea {
    width: 100%;
    height: 150px;
    max-height: 150px;
    resize: none;
    border-radius: 12px;
    border: solid 1px #ced4da !important;
    font-size: 14px;
    padding: 12px 24px;
    outline: none;

    &:focus {
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    &::placeholder {
        color: $grayAE;
        font-size: 14px;
        font-weight: 500;
    }
}
